import Elem from "./animated/Elem";

export default function Gift({ day, setShow }) {

    return (
        <div>
            <button onClick={() => { setShow(false) }} style={{ color: 'rgb(32, 80, 206)', position: 'absolute', zIndex: 100000, left: '-1em', top: '-1em', padding: '1em', borderRadius: '1em', border: '1px rgb(32, 80, 206) solid' }}>
                X
            </button>
            <img width={'80%'} style={{ marginTop: '4%', borderRadius: '1em' }} src={`/assets/gifts/${day}.jpg`} alt="background" />
            {
                (day === 1 || day === 6 || day === 9 || day === 13 || day === 16 || day === 21) &&
                <Elem left={230} top={-80} src={`/assets/sucre.png`} />
            }
            {
                (day === 1 || day === 4 || day === 9 || day === 14 || day === 16 || day === 19 || day === 23) &&
                <Elem left={-90} top={380} src={`/assets/c2.png`} />
            }
            {
                (day === 2 || day === 12) &&
                <Elem left={-90} top={-80} src={`/assets/boule.png`} />
            }
            {
                (day === 2 || day === 12 || day === 18) &&
                <Elem left={300} top={380} src={`/assets/c1.png`} />
            }
            {
                (day === 3 || day === 8) &&
                <Elem src={'/assets/serf.png'} top={150} left={-100} />
            }
            {
                (day === 4 || day === 14 || day === 19 || day === 23) &&
                <Elem src={'/assets/c3.png'} top={-70} left={300} />
            }{
                (day === 5 || day === 10 || day === 15 || day === 24) &&
                <Elem top={-80} left={100} src={'/assets/c2.png'} />
            }
            {
                (day === 5 || day === 10 || day === 15 || day === 24) &&
                <Elem src={'/assets/boule.png'} left={300} top={360} />
            }
            {
                (day === 6 || day === 13 || day === 21) &&
                <Elem src={'/assets/serf.png'} left={-90} top={330} />
            }
            {
                (day === 7 || day === 11 || day === 20) &&
                <Elem src={'/assets/serf.png'} left={300} top={-60} />
            }
            {
                (day === 7 || day === 11 || day === 20) &&
                <Elem src={'/assets/sucre.png'} top={360} left={0} />
            }
            <div style={{ color: 'white', padding: '1em' }}>
                {
                    (day === 1) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Suivre seconde par seconde le 24 décembre, l’avancée du Père Noël sur internet. Et ce sur plusieurs sites : ainsi <a target="blank" href="https://www.noradsanta.org/fr/">noradsanta.org</a> et le site dédié de Google <a target="blank" href="https://santatracker.google.com/intl/fr/">(santatracker)</a> proposent de suivre sa progression en le géolocalisant et de jouer en ligne en l’attendant.
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <a target="blank" href="https://www.wwf.fr/vous-informer/effet-panda/pour-une-celebration-plus-verte">Des conseils pour des fêtes plus responsables</a>
                    </>
                }
                {
                    (day === 2) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Recette de biscuits de Noël faciles à réaliser.
                            <a target="blank" href="https://www.marmiton.org/recettes/recette_petits-biscuits-de-noel_19726.aspx">Recette de biscuits de Noël</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <a target="blank" href="https://agirpourlatransition.ademe.fr/particuliers/conso/noel/sapin-noel-vraiment-vert">Comment choisir un sapin de Noël vraiment vert?</a>
                    </>
                }
                {
                    (day === 3) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Jeu en ligne :
                            <a target="blank" href="https://www.culturequizz.com/quiz/quiz-les-traditions-de-noel/">quiz sur les traditions de Noël dans le monde</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            <a target="blank" href="https://www.ecosapin.fr/">Ecosapin</a> encourage la consommation responsable de sapin de noël. En effet, chaque année cinq millions de sapins sont jetés, c’est pourquoi il est désormais possible de louer un sapin en pot afin qu’il soit replanté par la suite dans les forêts pour lui offrir une nouvelle vie.
                        </p>
                    </>
                }
                {
                    (day === 4) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Le podcast de Noël pour les enfants : qui vous emmène aux quatre coins de la planète pour y découvrir les traditions de Noël, à travers les yeux d’Andréas et de son doudou Moustache :
                            <a target="blank" href="https://www.lacabaneajouerdecdiscount.com/cest-noel/conte-de-noel-2021-le-podcast-gratuit/">Conte de Noël audio</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            <a target="blank" href="https://www.ecosapin.fr/">Ecosapin</a> encourage la consommation responsable de sapin de noël. En effet, chaque année cinq millions de sapins sont jetés, c’est pourquoi il est désormais possible de louer un sapin en pot afin qu’il soit replanté par la suite dans les forêts pour lui offrir une nouvelle vie.
                        </p>
                    </>
                }
                {
                    (day === 5) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Recette pour réaliser du <a target="blank" href="https://www.refuge-de-marie-louise.com/info/le-traditionnel-vin-chaud-de-savoie-?srsltid=AfmBOooLZfoH46hoSlwTEq-RfgauGS7aJbere9MqIVwORkfD42v_tXga">vin chaud maison</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Atelier pour transformer de vieux objets en décorations de Noël.
                            <a target="blank" href="https://www.educ-br.fr/creer-des-decorations-de-noel-avec-des-objets-de-recuperation/">Upcycling : créer des décorations de Noël</a>
                        </p>
                    </>
                }
                {
                    (day === 6) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Liste d’expositions ou marchés de Noël locaux :
                            <a target="blank" href="https://noel.org/Ile-de-France">Agenda des marchés de Noël en France</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Éco-challenge : acheter un cadeau de seconde main aujourd’hui.
                            <a target="blank" href="https://www.ethikdo.co/blog/pourquoi-offrir-cadeau-occasion-seconde-main-noel/">Où trouver des cadeaux de seconde main</a>
                        </p>
                    </>
                }
                {
                    (day === 7) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Une ambiance de festive avec une
                            <a target="blank" href="https://open.spotify.com/playlist/143W2Bzlc6fvmPi5ragAT2"> playlist de Noël sur Spotify</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Conseils pour
                            <a target="blank" href="https://www.foodhero.com/fr/blogues/reduire-le-gaspillage-pendant-les-fetes">réduire le gaspillage alimentaire à Noël</a>
                        </p>
                    </>
                }
                {
                    (day === 8) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            <a target="blank" href="https://www.delscookingtwist.com/fr/sapin-feuillete-aperitif-recette-de-noel/">Recette sapin feuilleté pour l’apéro</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            <a target="blank" href="https://furoshiki.fr/tuto-furoshiki#noeuds">Emballer ses cadeaux avec du tissu
                                Technique furoshiki</a>
                        </p>
                    </>
                }
                {
                    (day === 9) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            <a target="blank" href="https://deavita.fr/deco-de-fete/decoration-noel/carte-noel-brodee-idees-broderie-papier/">DIY : cartes de vœux simple et rapide</a> avec seulement du fil, du papier et une aiguille.
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Tutoriel vidéo pour créer une couronne de Noël en matériaux naturels <a target="blank" href="https://www.marieclaire.fr/idees/une-couronne-de-noel-diy-avec-des-branches-de-sapin-pommes-de-pins-et-oranges-sechees,1328769.asp">DIY : Fabriquer une couronne de Noël naturelle</a>
                        </p>
                    </>
                }
                {
                    (day === 10) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Suggestion de
                            <a href="https://www.canva.com/design/DAGXk8fGOQs/_6GPgqwaWatXQ7696n-zUg/view?utm_content=DAGXk8fGOQs&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=hf9ac250d31" target="blank">
                                films de Noël à regarder en famille
                            </a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            <a target="blank" href="https://rejoue.asso.fr/lassociation/">L'association Rejoué</a> collecte des jouets, jeux et livres. Ils sont ensuite triés selon les normes de sécurité, testés, complétés et nettoyés écologiquement puis vendus à petits prix (60 % du neuf) aux professionnel/les de l’enfance, aux familles ou offerts à des enfants qui en ont besoin grâce au soutien
                            d’entreprises partenaires.
                        </p>
                    </>
                }
                {
                    (day === 11) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Astuces pour préparer un chocolat chaud gourmand. <a target="blank" href="https://www.delscookingtwist.com/fr/chocolat-chaud-a-lancienne/">Recette de chocolat chaud à l’ancienne</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Guide sur les cadeaux immatériels et
                            expériences à offrir.
                            <a href="https://ralentir-en-famille.com/97-idees-cadeaux-immateriels/" target="blank">Idées de cadeaux immatériels</a>
                        </p>
                    </>
                }
                {
                    (day === 12) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            DIY pour fabriquer des bougies parfumées maison.
                            <a target="blank" href="https://www.youtube.com/watch?v=c1zzJI4TXPA">Tutoriel : Bougies parfumées maison</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Calendrier de l’Avent inversé: Projet solidaire, humain et plein de sens:
                            <a target="blank" href="https://willforchange.fr/calendrier-de-lavent-inverse/loperation/"> Calendrier de l’Avent inversé</a>
                        </p>
                    </>
                }
                {
                    (day === 13) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Les meilleurs livres pour plonger dans la magie du temps des fêtes : <a target="blank" href="https://www.noovomoi.ca/vivre/bien-etre/livres-temps-des-fetes-noel.html">Par ici la liste!</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Adopter un mode de vie plus durable : <a target="blank" href="https://basilicpodcast.com/vie-plus-durable-podcasts/">Podcasts sans culpabilité pour adopter de meilleurs gestes au quotidien</a>
                        </p>
                    </>
                }
                {
                    (day === 14) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Recette de cocktails festifs sans alcool.
                            <a target="blank" href="https://www.elle.be/fr/315430-recettes-mocktails-fetes-tester-fin-annee.html">Mocktailfestif pour les fêtes</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Où faire réparer de vieux objets à offrir.
                            <a href="https://www.linfodurable.fr/conso/ou-faire-reparer-ses-objets-pres-de-chez-soi-27123" target="blank">Réseau national des ressourceries et repaircafé</a>
                        </p>
                    </>
                }
                {
                    (day === 15) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Coloriages à télécharger pour enfants.
                            <a href="https://teteamodeler.ouest-france.fr/dossier/noel/coloriages.asp" target="blank">Coloriage de Noël gratuit</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Astuce pour utiliser les restes alimentaires des fêtes.
                            <a href="https://librairie.ademe.fr/agriculture-alimentation-foret-bioeconomie/7595-recettes-et-astuces-anti-gaspi.html">Recetteset astuces anti-gaspillage</a>
                        </p>
                    </>
                }
                {
                    (day === 16) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Menu végétarien pour un dîner de Noël.
                            <a href="https://menu-vegetarien.com/menu-noel-vegetarien/" target="blank">Recette : Menu végétarien festif</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Manger des fruits et légumes de saison, et encore mieux si possible en circuit court. <a target='blank' href="https://www.cuisineaz.com/les-aliments-de-saison-en-decembre-p693">Aliments du mois de décembre et recettes pour cocoter ses plats en fonction de la saison</a>
                        </p>
                    </>
                }
                {
                    (day === 17) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Jeu interactifs oldschool avec
                            ou sans enfants :
                            <a href="https://www.logicieleducatif.fr/jeux/domaine/noel" target="blank">C’est cadeau!</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Découvrir notre pays et
                            voyager « local ».
                            <a target="blank" href="https://www.youtube.com/watch?v=upgWZIT-fJk">Par ici nos beaux paysages!</a>
                        </p>
                    </>
                }
                {
                    (day === 18) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Lancer une soirée Karaoké.
                            <a href="https://www.karafun.com/" target="blank">Une plateforme avec un large choix</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Astuce pour économiser l’eau en cuisinant
                            pour les fêtes.
                            <a href="https://ma-maison-eco-confort.atlantic.fr/10-astuces-pour-economiser-leau-dans-sa-cuisine/" target="blank">Astuces économies d’eau en cuisine</a>
                        </p>
                    </>
                }
                {
                    (day === 19) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Des jeux de Noël en famille.
                            <a href="https://www.noovomoi.ca/style-et-maison/maison/article.jeux-de-noel.1.1558184.html" target="blank"></a>Quelques idées pour une soirée inoubliable
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Bocal à souvenirs : Proposes à ta famille/ton équipe/tes enfants de réutiliser un vieux pot en verre pour y stocker les meilleurs souvenirs de l'année.
                        </p>
                    </>
                }
                {
                    (day === 20) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Créer un poème recyclé (découpe des mots
                            dans un vieux magazine pour créer un poème
                            ou une phrase drôle) et partage le à tes proches.
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Astuce : Transformer son vieil agenda!
                            <a target="blank" href="https://agendas-vachon.com/recyclez-votre-vieil-agenda/">Fabriquer soi-même son papier</a>
                        </p>
                    </>
                }
                {
                    (day === 21) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Recette de bûche de Noël facile et sans cuisson.
                            <a href="https://www.youtube.com/watch?v=tfjwmofiolA" target="blank">Recette : bûche sans cuisson</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Faire du bénévolat pendant les fêtes
                            <a target="blank" href="https://www.jeveuxaider.gouv.fr/engagement/faire-du-benevolat-pendant-les-fetes-de-noel/">Bénévolat près de chez moi</a>
                        </p>
                    </>
                }
                {
                    (day === 22) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Idées de dernière minute pour des cadeaux faits maison. <a href="https://www.another-way.com/blogs/conseils-tutos/cadeau-fait-maison-diy-idee?srsltid=AfmBOopWu_iNuPKhxjT4jKQuXyzOYUFF-ushZgLw1lpIxRJzBFCZXgOt">Cadeaux DIY</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Guide pour voyager en train
                            depuis la France
                            <a target="blank" href="https://www.partir.com/ou-partir/destinations-en-train/">Source d’inspiration pour les
                                prochaines vacances</a>
                        </p>
                    </>
                }
                {
                    (day === 23) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            DIY pour fabriquer ses propres
                            chocolats de Noël.
                            <a target="blank" href="https://www.youtube.com/watch?v=BRIRVDsc6l0">Une recette facile et inratable</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Recycler son sapin :
                            <a href="https://www.up-to-us.veolia.com/fr/stop-pollution/recycler-sapin-noel-fetes-pollution">3 astuces simples et efficaces</a>
                        </p>
                    </>
                }
                {
                    (day === 24) &&
                    <>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'title' }}>
                            {day}
                        </h1>
                        <h2 style={{ fontFamily: 'font' }}>
                            Inspiration festive :
                        </h2>
                        <p className=".font-2" style={{ fontFamily: 'font2' }}>
                            Album collaboratif en ligne pour partager vos moments de Noël.
                            <a target="blank" href="https://www.hopla.photo/">Créer un album photo collaboratif</a>
                        </p>
                        <h2 style={{ fontFamily: 'font' }}>
                            Engagement responsable :
                        </h2>
                        <p>
                            Jouez et défiez votre entourage de la meilleure des façons en 2025! <a href="https://mapetiteplanete.org/decouvrir-le-jeu/" target="blank">Découvrez le jeu Ma Petite Planète</a>
                        </p>
                    </>
                }
            </div>
        </div>
    )
}