import './App.css';
import { useState, useEffect } from 'react';
import Star from './components/Star';
import Logo from './components/Logo';
import Gift from './gifts/Gift';

function App() {

  const [flakes, setFlakes] = useState([]);
  const [isOspen, setIsOspen] = useState(false);
  const [currenDay, setCurrentDay] = useState(null);

  const day = 24;

  const handleBouleClick = (targetDay) => {
    if (day >= targetDay) {
      setCurrentDay(targetDay);
      setIsOspen(true);
    }
  };

  useEffect(() => {
    const numberOfFlakes = 100;
    const newFlakes = Array.from({ length: numberOfFlakes }).map(() => ({
      id: Math.random(),
      left: Math.random() * 100, // Position horizontale aléatoire (en %)
      animationDuration: Math.random() * 5 + 5, // Durée aléatoire de la chute (5s à 10s)
      size: Math.random() * 5 + 5, // Taille du flocon (5px à 10px)
      delay: Math.random() * 5 // Délai d'animation aléatoire
    }));
    setFlakes(newFlakes);
  }, []);

  return (
    <div className="App">
      <div style={{ width: '100vw', textAlign: 'center', zIndex: 50000, position: 'absolute' }}>
        <Logo />
      </div>
      <div onClick={() => { setIsOspen(false) }} className='neige' style={{ zIndex: 0 }}>
        {flakes.map((flake) => (
          <div
            key={flake.id}
            className="flake"
            style={{
              left: `${flake.left}%`,
              animationDuration: `${flake.animationDuration}s`,
              width: `${flake.size}px`,
              height: `${flake.size}px`,
              animationDelay: `${flake.delay}s`
            }}
          />
        ))}
      </div>
      <p className='spacing' style={{ fontSize: '0.7em', width: '80%', left: '10%', position: 'absolute', textAlign: 'center', color: 'white', fontFamily: 'font2' }}>
        <h1 style={{ fontFamily: 'font', fontSize: '2em' }}>
          Calendrier de l’Avent
        </h1>
        <span>Rendez-vous ici quotidiennement jusqu’au 24 décembre pour découvrir une mine d’inspirations festives et responsables !
          <br /> De très belles fêtes de fin d’année à toutes et à tous.
        </span>
      </p>
      <div
        style={{
          background: `url('/assets/sapins/J ${day}.png')`,
          backgroundSize: 'cover',
        }}
        id="sapin"
        className="position-absolute bottom-0 start-50 translate-middle-x star-pulse"
      >
        <Star className={'star-pulse-2-2 '} top={'50%'} left={'70%'} />
        <Star className={'star-pulse-2-4 '} top={'18%'} left={'50%'} />
        <Star className={'star-pulse-2-6 '} top={'56%'} left={'4%'} />
        <Star className={'star-pulse-2-8 '} top={'74%'} left={'0%'} />
        <Star className={'star-pulse-3 '} top={'90%'} left={'80%'} />
        <Star className={'star-pulse-3-2 '} top={'2%'} left={'10%'} />
        <Star className={'star-pulse-3-4 '} top={'0%'} left={'66%'} />

        {/* Replace all onClick logic with handleBouleClick */}
        <div
          onClick={() => handleBouleClick(1)}
          className="boule"
          style={{ padding: '7%', top: '81%', left: '63%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(19)}
          className="boule"
          style={{ padding: '7%', top: '80%', left: '43%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(14)}
          className="boule"
          style={{ padding: '7%', top: '80%', left: '26%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(6)}
          className="boule"
          style={{ padding: '5%', top: '82%', left: '14%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(9)}
          className="boule"
          style={{ padding: '5%', top: '82%', left: '79%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(5)}
          className="boule"
          style={{ padding: '5%', top: '70%', left: '63%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(12)}
          className="boule"
          style={{ padding: '7%', top: '68%', left: '48%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(8)}
          className="boule"
          style={{ padding: '5%', top: '69%', left: '38%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(10)}
          className="boule"
          style={{ padding: '5%', top: '70%', left: '29%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(13)}
          className="boule"
          style={{ padding: '5%', top: '65%', left: '29%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(18)}
          className="boule"
          style={{ padding: '7.5%', top: '62%', left: '38%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(24)}
          className="boule"
          style={{ padding: '7.5%', top: '62.5%', left: '56%', width: '16%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(2)}
          className="boule"
          style={{ padding: '5%', top: '51%', left: '23.5%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(15)}
          className="boule"
          style={{ padding: '7%', top: '50%', left: '46.5%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(22)}
          className="boule"
          style={{ padding: '7%', top: '50%', left: '33%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(23)}
          className="boule"
          style={{ padding: '6.5%', top: '75%', left: '33%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(17)}
          className="boule"
          style={{ padding: '7%', top: '49%', left: '58%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(20)}
          className="boule"
          style={{ padding: '5%', top: '44.5%', left: '55%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(3)}
          className="boule"
          style={{ padding: '7%', top: '44%', left: '40%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(4)}
          className="boule"
          style={{ padding: '6%', top: '31.5%', left: '29%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(16)}
          className="boule"
          style={{ padding: '7%', top: '30%', left: '39%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(21)}
          className="boule"
          style={{ padding: '6.5%', top: '31%', left: '52%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(7)}
          className="boule"
          style={{ padding: '8%', top: '30%', left: '64%' }}
        ></div>
        <div
          onClick={() => handleBouleClick(11)}
          className="boule"
          style={{ padding: '5%', top: '26%', left: '54%', width: '12%' }}
        ></div>
      </div>

      <div className='position-absolute top-50 start-50 translate-middle' style={{ opacity: (isOspen) ? '1' : '0', transitionDuration: '1.2s', zIndex: (isOspen) ? 100000 : -1, width: '360px', background: '#2050CE', color: 'white', borderRadius: '2em', border: 'solid 0.4em white' }}>
        <Gift setShow={setIsOspen} day={currenDay} />
      </div>
      <div style={{ width: '100vw', left: 0, textAlign: 'center', position: 'fixed', bottom: 0 }}>
        <img width={'100px'} alt='treeosk' src='/assets/bowerby.png' />
      </div>
    </div>
  );
}

export default App;
