import React from "react";

export default function Star({top, left, className}) {
    return (
        <svg
        style={{position: 'fixed', top: top, left: left, zIndex: 1}}
        className={className}
        width="100"
        height="100"
        viewBox="0 0 148 131"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <g style={{ mixBlendMode: "plus-lighter" }} filter="url(#filter0_f_163_480)">
        <path
        d="M24 70.6875C57.4682 66.0577 61.5 60.3125 74 24C86.5 60.3125 86.5 65.5 124 75.875C86.5 86.25 86.5 86.25 74 107C67.7503 81.0625 67.7508 86.25 24 70.6875Z"
        fill="white"
        />
        </g>
        <defs>
        <filter
        id="filter0_f_163_480"
        x="0"
        y="0"
        width="148"
        height="131"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
        >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="12" result="effect1_foregroundBlur_163_480" />
        </filter>
        </defs>
        </svg>
    );
}
